import React from "react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
SwiperCore.use([Navigation, Autoplay]);

function ServiceArea(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const serviceSlider = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      }
    }
  };

  const services = [
  {
    id: 1,
    title: "Products & Technology",
    icon: "/img/icons/service-icon-1.png",
    description: "From concept to completion, we design and deliver innovative technology and products tailored to your needs."
  },
  {
    id: 2,
    title: "Team Augmentation",
    icon: "/img/icons/service-icon-2.png",
    description: "Providing custom-built teams of developers and designers, fine-tuned to deliver high-quality results."
  },
  {
    id: 3,
    title: "Design",
    icon: "/img/icons/service-icon-6.png",
    description: "Bringing your product vision to life with expert UI/UX design, prototypes, and engaging videos."
  },
  {
    id: 4,
    title: "Data & AI",
    icon: "/img/icons/service-icon-3.png",
    description: "Creating smarter applications and enabling better decisions through data engineering, analytics, machine learning, and LLM."
  },
  {
    id: 5,
    title: "DevOps & Cloud",
    icon: "/img/icons/service-icon-5.png",
    description: "Offering cloud-based solutions that ensure world-class security, scalability, and cost-effectiveness."
  }
];


  return (
    <>
      <section className={`${"services-area sec-mar"} ${props.layoutClass}`}>
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} ${props.white}`}>
              <span>We are here</span>
              <h2>Services</h2>
              <p>
                We offer almost every-tech service for your startup. Our services are designed to meet your needs and exceed your expectations.
              </p>
            </div>
          </div>
          <Swiper {...serviceSlider} className="swiper services-slider">
            <div className="swiper-wrapper">
              {services.map((service, index) => (
                <SwiperSlide
                  key={index}
                  className="swiper-slide wow animate fadeInUp"
                  data-wow-delay={`${index * 200}ms`}
                  data-wow-duration="1500ms"
                >
                  <div className={`single-service ${props.layoutClass}`}>
                    <span>{`0${index + 1}`}</span>
                    <div className="icon">
                      <img
                        src={`${process.env.PUBLIC_URL}${service.icon}`}
                        alt={`${service.title}-icon`}
                      />
                    </div>
                    <h4>{service.title}</h4>
                    <p>{service.description}</p>
                    <div className="read-btn">
                      <Link
                        to={`${process.env.PUBLIC_URL}/services`}
                        onClick={scrollTop}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  );
}

export default ServiceArea;
