import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import ServiceWrap from "./ServiceWrap";
import Testimonial from "../home/Testimonial";

function Service() {
  return (
    <>
      <div className="dlplatforms-wrap">
        <Breadcrumb pageName="Service" pageTitle="Service" />
        <ServiceWrap />
        {/* <SubscribArea /> */}
        <Testimonial />

      </div>
    </>
  );
}

export default Service;
