import React from "react";
import ServiceCard from "./ServiceCard";

function ServiceCardWrap() {
  return (
    <>
      <div className="services-grid sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Our Solutions</span>
              <h2>Services</h2>
              <p>
                Discover the innovative and bespoke solutions we offer to
                transform your business. Our range of services is designed to
                cater to your unique needs, ensuring the highest level of
                quality and satisfaction. From the initial concept to the final
                product, we are committed to excellence in every step of the
                process.
              </p>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={1}
                image="/img/icons/service-icon-1.png"
                title="Products & Technology"
                details="From concept to completion, we design and deliver innovative technology and products tailored to your needs. Our team excels at creating cutting-edge solutions that drive efficiency and growth, ensuring your business stays ahead of the competition."
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={2}
                image="/img/icons/service-icon-2.png"
                title="Team Augmentation"
                details="Providing custom-built teams of developers and designers, fine-tuned to deliver high-quality results. Our experts seamlessly integrate with your in-house team, offering flexibility and expertise to help you achieve your project goals efficiently."
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={3}
                image="/img/icons/service-icon-6.png"
                title="Design"
                details="Bringing your product vision to life with expert UI/UX design, prototypes, and engaging videos. We focus on creating intuitive and visually appealing designs that enhance user experience and drive customer engagement."
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={4}
                image="/img/icons/service-icon-3.png"
                title="Data & AI"
                details="Creating smarter applications and enabling better decisions through data engineering, analytics, machine learning, and LLM. Our solutions leverage the power of AI to provide actionable insights and drive business growth."
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={5}
                image="/img/icons/service-icon-5.png"
                title="DevOps & Cloud"
                details="Offering cloud-based solutions that ensure world-class security, scalability, and cost-effectiveness. Our DevOps practices enhance development processes, providing continuous delivery and reliable infrastructure for your applications."
              />


            </div>

            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={5}
                image="/img/icons/feature-icon-4.png"
                title="& More"
                details="Our team is equipped to handle a wide variety of tasks tailored to your business needs. Whether you require specialized development, custom integrations, comprehensive digital marketing strategies, or other unique services, we are here to provide solutions that drive success and growth."
              />

              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCardWrap;
