import React from "react";
import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import ProjectWrap from "./ProjectWrap";
import Testimonial from "../home/Testimonial";

function Project() {
  return (
    <>
      <div className="dlplatforms-wrap">
        <AnimateLine />
        <Breadcrumb pageName="Project " pageTitle="Project" />
        <ProjectWrap />
        {/* <SubscribArea /> */}
        <Testimonial />
      </div>
    </>
  );
}

export default Project;
