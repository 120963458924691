import React from "react";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import { Link } from "react-router-dom";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ClientTestimonial() {
    const testimonial = {
        loop: true,
        slidesPerView: 2,
        centeredSlides: true,
        spaceBetween: 30,
        slideToClickedSlide: true,

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 2,
            },
            1400: {
                slidesPerView: 2,
            },
            1800: {
                slidesPerView: 2,
            }
        }
    };

  return (
    <>
      <div className="testimonial-area">
        <div className="container-fluid p-0">
          <div className="title-wrap">
            <div className="sec-title white">
              <span>Testimonial</span>
              <h2>What Our Clients Say</h2>
              <p>
                Discover why our clients love working with us. Hear their stories of success and satisfaction.
              </p>
            </div>
          </div>
          <Swiper {...testimonial} className="swiper testimonial-slider">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Martha Maldonado</h5>
                  <span>Executive Chairman</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                    Working with this team has been a game-changer. Their expertise and dedication have transformed our vision into a reality. We couldn't be happier with the results!
                  </p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL +"/img/reviewer.jpg"} alt="Martha Maldonado" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Jesmin Korac</h5>
                  <span>Director</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                    The level of professionalism and creativity brought to our project was outstanding. They understood our needs perfectly and delivered beyond our expectations.
                  </p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL + "/img/reviewer-1.jpg"} alt="Jesmin Korac" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Michael Smith</h5>
                  <span>Product Manager</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                    Exceptional service and remarkable results! The team was attentive, knowledgeable, and delivered on every promise. Our project success is a testament to their hard work.
                  </p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL +"/img/reviewer-2.jpg"} alt="Michael Smith" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Anna Johnson</h5>
                  <span>CEO</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                    We are thrilled with the work done by this incredible team. Their innovative approach and attention to detail made all the difference. Highly recommend their services!
                  </p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL + "/img/reviewer-3.jpg"} alt="Anna Johnson" />
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default ClientTestimonial;
