const data = [
    {
        id:1,
        title:"350,000+ Users & Counting",
        image:"img/project/dlupload.png",
        category: "developing",
        details: "DLUpload ",
        link: "https://dlupload.com/",
    },
    {
        id:2,
        title:"1,000+ Students & Counting ",
        image:"img/project/collegetiffin.png",
        category: "web",
        details: "CollegeTiffin",
        link: "https://collegetiffin.com/",
    },
  {
        id:3,
        title:"Study AI Automation Software",
        image:"img/project/kakshya.png",
        category: "App",
      details: "Kakshya",
        link: "https://kakshya-live.vercel.app/",
    },
    
    // {
    //     id:7,
    //     title:"3D Design",
    //     image:"img/project/project-5.jpg",
    //     category: "Graphic",
    //     details:"Low Poly Base Mesh",
    // },
    // {
    //     id:8,
    //     title:"3D Design",
    //     image:"img/project/project-1.jpg",
    //     category: "Graphic",
    //     details:"Low Poly Base Mesh",
    // },
    // {
    //     id:9,
    //     title:"UI Kit",
    //     image:"img/project/project-2.jpg",
    //     category: "UI",
    //     details:"Low Poly Base Mesh",
    // },
    // {
    //     id:10,
    //     title:"UI Kit",
    //     image:"img/project/project-4.jpg",
    //     category: "UI",
    //     details:"Low Poly Base Mesh",
    // },
    // {
    //     id:11,
    //     title:"App",
    //     image:"img/project/project-3.jpg",
    //     category: "App",
    //     details:"Mobile Crypto Wallet",
    // },
    // {
    //     id:12,
    //     title:"App",
    //     image:"img/project/project-1.jpg",
    //     category: "App",
    //     details:"Mobile Crypto Wallet",
    // },
];

export default data;