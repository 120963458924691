import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom'

function HeroArea() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const checkDevice = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', checkDevice);
    return () => {
      window.removeEventListener('resize', checkDevice);
    };
  }, []);

  return (
    <>
     <section className="hero-area">
        <div className="ken-burns-slideshow">
          <img src={process.env.PUBLIC_URL + "/img/hero-banner.jpg"} alt="HeroBanner" />
        </div>
        <div className="verticale-social">
          <ul className="vertical-media">
            <li><a href="https://www.facebook.com/">Facebook</a></li>
            <li><a href="https://www.instagram.com/">Instagram</a></li>
            <li><a href="https://www.linkedin.com/">Linkedin</a></li>
          </ul>
        </div>
        <div className="hero-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="hero-content">
                  <h1>Your Tech Outsourcing<span>Partner</span></h1>
                  <p>Work with us & reduce your cost by 70% <br></br> Handover your tech stress & <b>focus beyond</b></p>
                  <div className="buttons">
                    <div className="cmn-btn">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link to={`${process.env.PUBLIC_URL}/about-us`} onClick={scrollTop}>About Us</Link>
                    </div>
                        <div className="cmn-btn layout-two">
      <div className="line-1" />
      <div className="line-2" />
      {isMobile ? (
<a href="https://calendly.com/dlplatforms/30min/" target="_blank" rel="noopener noreferrer" onClick={scrollTop}>
  Book A Call
</a>      ) : (
        <Link to={`${process.env.PUBLIC_URL}/projects`} onClick={scrollTop}>See Projects</Link>
      )}
    </div>

                  </div>
                </div>
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default HeroArea