import React from 'react'
import Footer from './common/Footer'
import Header from './common/Header'

function Layout({children}) {
  console.log("the count is "+React.Children.count(children)); // Add this line

  return (
    <>
          <Header />
          {children}
          <Footer />
    </>
      
  )
}

export default Layout