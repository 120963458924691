import React, { useState } from 'react'
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutArea(props) {
  const [isOpen, setOpen]=useState(false)
  return (
    <>
      <br /><br /><br />
      <section className="about-area sec-mar-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 or-2 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className={`${"sec-title layout2"} ${props.white}`}>
                <span>Better to know</span>
                <h2>About Us</h2>
              </div>
              <div className={`${"about-left"} ${props.layoutClass}`}>
                <p>We are startup friendly tech service company dedicated to managing and maintaining your business tech aspects at industry lowest costs. As your go-to partner, we offer a <b>10% discount</b> on any software bid, ensuring you always get the winning cost. </p>
                <div className={`${"company-since"} `}>

                  <div className="company-logo"> 
                    <span>Recent Awards :</span>
                    <img className="" src={process.env.PUBLIC_URL + '/img/gsea.png'} alt="about-images" />
                    
  <img className="ml-5" src={process.env.PUBLIC_URL + '/img/apac.png'} alt="about-images" />
                  </div>
                                    <p>Most Innovative Cloud Solution <b>APAC Insider, 2024</b></p>

                  <p>Top 3 Winners at <b>GSEA Nepal, 2024</b></p>
                                   </div>
                
              </div>
            </div>
            <div className="col-lg-6 or-1 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="about-right">
                <div className="banner-1">
                  <img src={process.env.PUBLIC_URL + '/img/about-baner-1.jpg'} alt="about-images" />
                </div>
                <div className="banner-2">
                  <img className="" src={process.env.PUBLIC_URL + '/img/about-baner-2.jpg'} alt="about-images" />
                  
                  {/* <div className="banner2-inner">
                    <div className="play">
                      <div className="video-popup" onClick={()=>setOpen(true)} ><i className="fas fa-play" /></div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>  
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="IUN664s7N-c"
          autoplay = {1}
          onClose={() => setOpen(false)}
        />
      </React.Fragment> 
    </>)
}

export default AboutArea